import React, { useCallback, useEffect, useState } from 'react'
import Helmet from 'react-helmet'
import firebase from 'gatsby-plugin-firebase';

import Layout from '../components/layout'
import Header from '../components/Header'
import { useForm } from 'react-hook-form'
import { Button, Input } from 'semantic-ui-react'
import { useUserState } from '../context/UserContext';
import { navigate } from 'gatsby';

const SignIn = () => {
  const { register, handleSubmit, getValues, setValue, trigger, errors, unregister } = useForm();
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState(false);
  const { user } = useUserState();

  useEffect(() => {
    register({ name: 'email' }, { required: true });
    register({ name: 'password' }, { required: true });
  }, []);

  useEffect(() => {
    if (user) {
      navigate('/admin', { replace: true });
    }
  }, [user]);

  const onChange = useCallback(async (_event, { name, value }) => {
    setError(false);
    setValue(name, value);
    await trigger(name);
  }, [setValue, trigger]);

  const onSubmit = useCallback(() => {
    setSubmitting(true);
    const formValues = getValues();
    firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION).then(() => {
      firebase.auth().signInWithEmailAndPassword(formValues.email, formValues.password).then((user) => {
        firebase.auth().currentUser.getIdToken()
          .then((token) => {
            setSubmitting(false);
          })
          .catch((error) => {
            setError(true);
            setSubmitting(false);
            console.error(error);
          });
      }).catch((error) => {
        setError(true);
        setSubmitting(false);
        console.error(error);
      });
    });
  }, []);

  return (
    <Layout title="Sign In" >
      <Header title="Sign In" subtitle="Sign in for admin access" />
      <div id="main">
        <section id="content" className="main">
          <form onSubmit={handleSubmit(onSubmit)}>
            {error && <span style={{ color: '#dc3545' }}>Invalid email and/or password, please try again</span>}
            <Input
              error={Boolean(errors.email)}
              fluid
              label="Email"
              name="email"
              onChange={onChange}
              placeholder="Email"
              type="email"
            />
            <Input
              error={Boolean(errors.password)}
              fluid
              label="Password"
              name="password"
              onChange={onChange}
              placeholder="Password"
              type="password"
            />
            <div className="submit">
              <Button
                type="submit"
                onClick={handleSubmit(onSubmit)}
                loading={submitting}
                disabled={submitting}
              >
                Sign In
              </Button>
            </div>
          </form>
        </section>
      </div>
    </Layout>
  );
}

export default SignIn;
